<template>
    <div @dragover.prevent @drop="onDrop" class="upload-wrapper">
        <label>
            <div :class="inputFile !== null || imageSrc !== null ? '' : 'p-3'">
                <div :class="inputFile !== null || imageSrc !== null ? 'img-wrapper' : 'img-frame'">
                    <input :name="name+'-delete'" type="hidden" v-if="deleteFile">
                    <input v-if="inputFile" type="hidden" :name="name" :value="inputFile">
                    <input :id="id" :accept="accept" type="file" ref="addedFile" v-on:change="onAddFile"/>
                    <img :src="imageSrc" alt=""/>

                    <div class="text-xs" v-if="imageSrc === null">
                        <a v-show="showUploadFileLabel">Upload</a>
                        {{label}}
                        <div v-show="showFileTypes">
                            PNG, JPG, GIF up to 10MB
                        </div>
                    </div>
                    <div class="trash"
                         :class="light ? 'light' : ''"
                         @click="()=>{this.imageSrc = null; this.inputFile = null , this.deleteFile=true , $emit('image-delete')}"
                         v-if="inputFile !== null || imageSrc !== null">
                        <img :src="light ? '/img/trash_light.svg' : '/img/trash.svg'" alt="">
                        Delete
                    </div>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                file: null,
                imageSrc: null,
                inputFile: null,
                deleteFile: false,
            }
        },
        props: {
            id: {
                type: String,
                required: false,
                default: () => {
                    return null;
                }
            },
            name: {
                type: String,
                required: false,
                default: () => {
                    return null;
                }
            },
            accept: {
                type: String,
                required: false,
                default: () => {
                    return "image/*";
                }
            },
            label: {
                type: String,
                required: false,
                default: () => {
                    return "";
                }
            },
            light: {
                type: Boolean,
                required: false,
                default: () => {
                    return false
                }
            },
            old: {
                type: String,
                required: false,
                default: () => {
                    return null
                }
            },
            showUploadFileLabel: {
                required: false,
                default: () => {
                    return false
                }
            },
            showFileTypes: {
                required: false,
                default: () => {
                    return false
                }
            }
        },
        created() {
            // if (this.old) {
            //     this.imageSrc = this.old;
            //     let base64regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
            //     if (base64regex.test(this.old)) {
            //         this.inputFile = this.old;
            //     }
            // }
        },
        methods: {
            onDrop(e) {
                e.stopPropagation();
                e.preventDefault();
                this.file = e.dataTransfer.files[0];
                this.handleFileChange()
            },

            onAddFile() {
                this.file = this.$refs.addedFile.files[0];
                this.handleFileChange()
            },
            handleFileChange() {

                /*
                  Initialize a File Reader object
                */
                let reader = new FileReader();
                /*
                  Add an event listener to the reader that when the file
                  has been loaded, we flag the show preview as true and set the
                  image to be what was read from the reader.
                */
                reader.addEventListener("load", function () {
                    this.imageSrc = reader.result;
                    this.inputFile = reader.result;
                    this.$emit('file-loaded', reader.result);
                    this.deleteFile = false;
                }.bind(this), false);

                /*
                  Check to see if the file is not empty.
                */
                if (this.file) {
                    /*
                      Ensure the file is an image file.
                    */
                    if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
                        /*
                          Fire the readAsDataURL method which will read the file in and
                          upon completion fire a 'load' event which we will listen to and
                          display the image in the preview.
                        */
                        reader.readAsDataURL(this.file);
                    }
                }

            }
        }
    }
</script>
<style lang="scss" scoped>
    /*.upload-wrapper {*/
    /*    height: 130px;*/
    /*    border: 1px solid #DEE4EC;*/
    /*    box-sizing: border-box;*/
    /*    border-radius: 5px;*/
    /*    position: relative;*/
    /*    overflow: hidden;*/
    /*}*/

    label {
        box-sizing: border-box;
        img {
            max-width: 200px;
            height: 130px;
            object-fit: contain;
        }

        input {
            display: none;
        }

        .p-3 {
            box-sizing: border-box;
        }
    }

    .trash {
        width: 62px;
        padding: 0;
        color: #6A7685;
        font-size: 12px;
        line-height: 24px;
        cursor: pointer;
        position: absolute;
        bottom: 12px;
        right: 12px;

        img {
            margin-bottom: 2px;
        }
    }
    .text-xs {
        text-align: center;
        vertical-align: center;
        color: #6A7685;
    }

    .img-frame {
        border: 1px dashed #DEE4EC;
        border-radius: 5px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 140px;
        /*width: 353px;*/
        box-sizing: border-box;
    }

    .img-wrapper {
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #DEE4EC;
        height: 140px;
    }


    a {
        font-size: .875rem !important;
        text-decoration: none !important;
        cursor: pointer;

        &:hover {
            color: #667eea;
        }
    }

</style>
