import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import App from './App.vue'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import {email, required, max_value , min_value} from 'vee-validate/dist/rules';

Vue.use(Vue2Filters);

extend('email', email);

extend('required', {
    ...required,
    message: 'This field is required'
});

extend('max_value', {
    ...max_value,
    message: 'This field may not be greater than 100.'
});

extend('min_value', {
    ...min_value,
    message: 'This field may not be lower than 1.'
});

extend('positive', value => {
    if (value >= 0) {
        return true;
    }

    return 'This field must be a positive number';
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.config.devtools = true;

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')
