<template>
    <div id="app">
        <div class="free_generator">
            <ValidationObserver v-slot="{ handleSubmit }">
                <div v-show="!showPreviewInvoice" class="free_generator__card main">
                    <div class="free_generator__card-header">
                        <div>
                            <span>
                                Invoice #
                            </span>
                            <input class="free_generator__input" type="number" placeholder="1" min="0" name="invoice_number" v-model="invoice.number">
                        </div>

                        <div>
                            <span class="free_generator__date">
                                <date-picker :clearable="false"
                                             v-model="invoice.issued_at"
                                             value-type="format">
                                    <div slot="input" class="input">
                                        <img src="./assets/free-generator/calendar-alt.svg" alt="">
                                        <span>Date issued:</span>
                                        <span>{{invoice.issued_at | moment(invoice.date_format)}}</span>
                                    </div>
                                </date-picker>
                            </span>
                            <span class="free_generator__date payment-terms">
                                <date-picker :clearable="false"
                                             v-model="invoice.due_at"
                                             value-type="format">
                                    <div slot="input" class="input">
                                        <img src="./assets/free-generator/calendar-alt.svg" alt="">
                                        <span>Date due:</span>
                                        <span>{{invoice.due_at | moment(invoice.date_format)}}</span>
                                    </div>
                                    <div slot="sidebar">
                                        <div class="options">
                                            <span>
                                                Payment Terms
                                            </span>
                                            <div @click="addDays(15)">
                                                15 days
                                            </div>
                                            <div @click="addDays(30)">
                                                30 days
                                            </div>
                                            <div @click="addDays(45)">
                                                45 days
                                            </div>
                                            <div @click="addDays(60)">
                                                60 days
                                            </div>
                                            <div @click="addDays(90)">
                                                90 days
                                            </div>
                                        </div>
                                    </div>
                                </date-picker>
                            </span>
                            <span class="free_generator__edit-date-format">
                                <a href="#" @click="showDateFormatMenu = !showDateFormatMenu">
                                    <img src="./assets/free-generator/cog.svg" alt="img">
                                    <span>Edit date format</span>
                                </a>
                                <div id="date_format_id" class="free_generator__edit-date-format-menu" v-if="showDateFormatMenu" v-click-outside="closeDateFormatMenu">
                                    <div :key="dateFormat.id" v-for="dateFormat in dateFormats"
                                         @click="invoice.date_format_id = dateFormat.id, invoice.date_format = dateFormat.format"
                                         :class="invoice.date_format_id === dateFormat.id ? 'active' : null">{{dateFormat.format}}
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="free_generator__card-body">
                        <div class="free_generator__row">
                            <div>
                                <h3>
                                    Invoice
                                </h3>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <input type="text" placeholder="Job description" v-model="invoice.job_description" :class="{'is-invalid' : errors.length > 0}">
                                    <span class="invalid-feedback">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="free_generator__logo">
                                <!--                            <input type="file" class="free_generator__upload-area">-->
                                <file-preview
                                        id="light_logo"
                                        name="light_logo"
                                        label="or drag and drop your company logo"
                                        :show-upload-file-label="true"
                                        :show-file-types="true"
                                        @file-loaded="invoice.logo = $event"
                                        @image-delete="invoice.logo = null"
                                        :old="invoice.logo">
                                </file-preview>
                            </div>
                        </div>
                        <hr>
                        <div class="free_generator__row input-group">
                            <div>
                                <div>
                                    Bill from:
                                </div>
                                <div>
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <input type="text" placeholder="Your Company Name" v-model="invoice.bill_from.name" :class="{'is-invalid' : errors.length > 0}">
                                    </ValidationProvider>
                                </div>

                                <div>
                                    <span>
                                         <textarea name="company_address"
                                                   id="company_address"
                                                   rows="3"
                                                   placeholder="Company Address"
                                                   v-model="invoice.bill_from.address">
                                        </textarea>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <input type="text" placeholder="Country" v-model="invoice.bill_from.country">
                                    </span>
                                </div>
                                <div>
                                    <ValidationProvider rules="email" v-slot="{ errors }">
                                        <input type="email" placeholder="Email" v-model="invoice.bill_from.email" :class="{'is-invalid' : errors.length > 0}">
                                    </ValidationProvider>
                                </div>
                                <div>
                                    <span>
                                        <input type="text" placeholder="Phone Number" v-model="invoice.bill_from.phone">
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    Bill to:
                                </div>
                                <div>
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <input type="text" placeholder="Customer Name" v-model="invoice.bill_to.name" :class="{'is-invalid' : errors.length > 0}">
                                    </ValidationProvider>
                                </div>
                                <div>
                                    <span>
                                        <textarea name="customer_company_address"
                                                  id="customer_company_address"
                                                  rows="3"
                                                  placeholder="Customer Company Address"
                                                  v-model="invoice.bill_to.address">
                                        </textarea>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <input type="text" placeholder="Country" v-model="invoice.bill_to.country">
                                    </span>
                                </div>
                                <div>
                                    <ValidationProvider rules="email" v-slot="{ errors }">
                                        <input type="email" placeholder="Email" v-model="invoice.bill_to.email" :class="{'is-invalid' : errors.length > 0}">
                                    </ValidationProvider>
                                </div>
                                <div>
                                    <span>
                                        <input type="text" placeholder="Phone Number" v-model="invoice.bill_to.phone">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span class="free_generator__currency">
                                <img src="./assets/free-generator/money-bill-alt.svg" alt="">
                                <label for="currency_id">Currency:</label>
                                <select name="currency_id" id="currency_id" v-model="invoice.currency_id">
                                    <option :key="currency.id" v-for="currency in currencies"
                                            :value="currency.id" :selected="invoice.currency_id === currency.id">{{currency.name}}
                                    </option>
                                </select>
                            </span>
                        </div>
                        <div>
                            <ValidationObserver v-slot="{ invalid }">
                                <div class="free_generator__item-task">
                                    <div class="free_generator__row">
                                        <div class="free_generator__item-task-column-6">
                                            Item/Task
                                        </div>
                                        <div class="free_generator__item-task-column-2">
                                            Cost
                                        </div>
                                        <div class="free_generator__item-task-column-1">
                                            QTY
                                        </div>
                                        <div class="free_generator__item-task-column-3">
                                            Price
                                        </div>
                                    </div>
                                    <draggable :list="invoice.free_invoice_items" @end="onDragEnd" group="items" handle=".handle">
                                        <div v-for="(item, index) in invoice.free_invoice_items" :key="index">
                                            <item-task
                                                    :currency="currency"
                                                    :item="item"
                                                    @item-changed="itemChanged($event , index)"
                                                    @remove="removeInvoiceItem(index)"
                                            ></item-task>
                                        </div>
                                    </draggable>
                                </div>
                                <button type="button" @click="addNewItem" :disabled="invalid">
                                    <img src="./assets/free-generator/plus.svg" alt="">
                                    Add Line
                                </button>
                            </ValidationObserver>
                        </div>
                        <hr>
                        <div class="free_generator__row">
                            <div>
                                <div class="free_generator__payment_terms">
                                    <label for="payment_terms">Payment Terms</label>
                                    <a @click="invoice.show_payment_terms = !invoice.show_payment_terms">
                                        <img src="./assets/free-generator/eye.svg" alt="" v-if="invoice.show_payment_terms">
                                        <img src="./assets/free-generator/eye-slash.svg" alt="" v-if="!invoice.show_payment_terms">
                                        Show
                                    </a>
                                    <textarea name="payment_terms" id="payment_terms" rows="3" v-if="invoice.show_payment_terms" v-model="invoice.payment_terms"></textarea>
                                </div>
                                <div class="free_generator__client_notes">
                                    <label for="client_notes">Client Notes</label>
                                    <a @click="invoice.show_client_notes = !invoice.show_client_notes">
                                        <img src="./assets/free-generator/eye.svg" alt="" v-if="invoice.show_client_notes">
                                        <img src="./assets/free-generator/eye-slash.svg" alt="" v-if="!invoice.show_client_notes">Show
                                    </a>
                                    <textarea name="client_notes" id="client_notes" rows="3" v-if="invoice.show_client_notes" v-model="invoice.client_notes"></textarea>
                                </div>
                            </div>
                            <div class="free_generator__payment">
                                <div class="free_generator__space-between">
                                    <div>
                                        Subtotal
                                    </div>
                                    <div>
                                        {{subTotal | currency(currency.symbol , 2 , currency.formatted_object) }}
                                    </div>
                                </div>
                                <div class="free_generator__space-between">
                                    <div>
                                        Discount
                                    </div>
                                    <div>
                                        {{invoiceDiscount | currency(currency.symbol , 2 , currency.formatted_object)}}
                                    </div>
                                </div>
                                <div class="free_generator__discount-whole-invoice">
                                    <a v-if="!showDiscountWholeInvoice" @click="showDiscountWholeInvoice = true">
                                        <img src="./assets/free-generator/plus.svg" alt="">Discount entire invoice
                                    </a>
                                    <div v-if="showDiscountWholeInvoice" class="free_generator__row">
                                        <div>
                                        </div>
                                        <div>
                                            <ValidationProvider :rules="invoice.discount_type === 'percentage' ? 'max_value:100|positive' : 'positive'" v-slot="{ errors }">
                                                <input type="number" v-model="invoice.discount" :class="{'is-invalid' : errors.length > 0}">
                                                <span>
                                                    <div @click="invoice.discount_type = 'percentage'"
                                                         :class="invoice.discount_type === 'percentage' ? 'active' : ''">
                                                        %
                                                    </div>
                                                    <div @click="invoice.discount_type = 'flat'"
                                                         :class="invoice.discount_type === 'flat' ? 'active' : ''">
                                                        $
                                                    </div>
                                                </span>
                                                <div class="invalid-feedback"
                                                     role="alert">{{ errors[0] }}
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <img src="./assets/free-generator/trash.svg" alt="" v-if="showDiscountWholeInvoice" @click="showDiscountWholeInvoice = false , invoice.discount = 0">
                                </div>
                                <div class="free_generator__space-between" :key="index" v-for="(groupTax , index) in groupTaxes()">
                                    <div>
                                        {{index}}
                                    </div>
                                    <div>
                                        {{groupTax.total | currency(currency.symbol , 2 , currency.formatted_object)}}
                                    </div>
                                </div>
                                <hr>
                                <div class="free_generator__space-between">
                                    <div>
                                        Invoice total
                                    </div>
                                    <div>
                                        {{(subTotalWithTaxes - invoiceDiscount ) | currency(currency.symbol , 2 , currency.formatted_object)}}
                                    </div>
                                </div>
                                <!--                                <div class="free_generator__space-between">-->
                                <!--                                    <div>-->
                                <!--                                        Paid to date-->
                                <!--                                    </div>-->
                                <!--                                    <div>-->
                                <!--                                        $0.00-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <div class="free_generator__space-between">
                                    <div>
                                        Amount due
                                    </div>
                                    <div>
                                        {{(subTotalWithTaxes - invoiceDiscount) | currency(currency.symbol , 2 , currency.formatted_object)}}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr class="free_generator__hr-dashed">
                        <div class="free_generator__space-between">
                            <div>
                                Payment Stub
                            </div>
                            <div>
                                <a @click="invoice.show_payment_stub = !invoice.show_payment_stub">
                                    <img src="./assets/free-generator/eye.svg" alt="" v-if="invoice.show_payment_stub">
                                    <img src="./assets/free-generator/eye-slash.svg" alt="" v-if="!invoice.show_payment_stub">Show Payment Stub
                                </a>
                            </div>
                        </div>
                        <div class="free_generator__payment-stub free_generator__row" v-if="invoice.show_payment_stub">
                            <div>
                                <div>
                                    {{invoice.bill_from.name}}
                                </div>
                                <div v-html="invoice.bill_from.address ? invoice.bill_from.address.replace(/(\\r)*\n/g, '<br />') : ''">
                                </div>
                                <div>
                                    {{invoice.bill_from.country}}
                                </div>
                                <div>
                                    <span>
                                        Tel:
                                    </span>
                                    {{invoice.bill_from.phone}}
                                </div>
                                <div>
                                    <span>
                                        Email:
                                    </span>
                                    {{invoice.bill_from.email}}
                                </div>
                            </div>
                            <div>
                                <div class="free_generator__space-between">
                                    <div>
                                        Client
                                    </div>
                                    <div>
                                        {{invoice.bill_to.name}}
                                    </div>
                                </div>
                                <div class="free_generator__space-between">
                                    <div>
                                        Invoice #
                                    </div>
                                    <div>
                                        {{invoice.number}}
                                    </div>
                                </div>
                                <div class="free_generator__space-between" :key="index" v-for="(groupTax , index) in groupTaxes()">
                                    <div>
                                        {{index}}
                                    </div>
                                    <div>
                                        {{groupTax.total | currency(currency.symbol , 2 , currency.formatted_object)}}
                                    </div>
                                </div>
                                <hr>
                                <div class="free_generator__space-between">
                                    <div>
                                        Amount Due ({{currency.code}})
                                    </div>
                                    <div>
                                        {{ (subTotalWithTaxes - invoiceDiscount) | currency(currency.symbol , 2 , currency.formatted_object) }}
                                    </div>
                                </div>
                                <!--                                <div class="free_generator__space-between">-->
                                <!--                                    <div>-->
                                <!--                                        Amount Enclosed-->
                                <!--                                    </div>-->
                                <!--                                    <div>-->
                                <!--                                        400.00-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="showPreviewInvoice" class="free_generator__card preview main">
                    <div class="free_generator__card-header">
                        <div>
                            <span>
                                Invoice # {{invoice.number}}
                            </span>
                        </div>
                        <div>
                            <span class="free_generator__date">
                                <span>Date issued:</span>
                                <span>{{invoice.issued_at | moment(invoice.date_format)}}</span>
                            </span>
                            <span class="free_generator__date">
                                <span>Date due:</span>
                                <span>{{invoice.due_at | moment(invoice.date_format)}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="free_generator__card-body">
                        <div class="free_generator__row">
                            <div>
                                <h3>
                                    Invoice
                                </h3>
                                <span>
                                    {{invoice.job_description}}
                                </span>
                            </div>
                            <div class="free_generator__logo">
                                <img :src="invoice.logo" alt="Logo" v-if="invoice.logo">
                            </div>
                        </div>
                        <hr>
                        <div class="free_generator__row">
                            <div>
                                <div>
                                    Bill from:
                                </div>
                                <div>
                                    {{invoice.bill_from.name}}
                                </div>
                                <div v-html="invoice.bill_from.address ? invoice.bill_from.address.replace(/(\\r)*\n/g, '<br />') : ''">
                                    {{invoice.bill_from.address}}
                                </div>
                                <div>
                                    {{invoice.bill_from.country}}
                                </div>
                                <div>
                                    {{invoice.bill_from.email}}
                                </div>
                                <div>
                                    {{invoice.bill_from.phone}}
                                </div>

                            </div>
                            <div class="text-right">
                                <div>
                                    Bill to:
                                </div>
                                <div>
                                    {{invoice.bill_to.name}}
                                </div>
                                <div v-html="invoice.bill_to.address ? invoice.bill_to.address.replace(/(\\r)*\n/g, '<br />') : ''">
                                </div>
                                <div>
                                    {{invoice.bill_to.country}}
                                </div>
                                <div>
                                    {{invoice.bill_to.email}}
                                </div>
                                <div>
                                    {{invoice.bill_to.phone}}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="free_generator__item-task preview">
                                <div class="free_generator__row">
                                    <div class="free_generator__item-task-column-6">
                                        Item/Task
                                    </div>
                                    <div class="free_generator__item-task-column-2">
                                        Cost
                                    </div>
                                    <div class="free_generator__item-task-column-1">
                                        QTY
                                    </div>
                                    <div class="free_generator__item-task-column-3">
                                        Price
                                    </div>
                                </div>
                                <div>
                                    <div v-for="(item, index) in invoice.free_invoice_items" :key="index">
                                        <div class="free_generator__row">
                                            <div class="free_generator__item-task-column-6">
                                                {{item.name}}
                                                <div>
                                                    {{item.description}}
                                                </div>
                                            </div>
                                            <div class="free_generator__item-task-column-2">
                                                {{item.price | currency(currency.symbol , 2 , currency.formatted_object) }}
                                            </div>
                                            <div class="free_generator__item-task-column-1">
                                                {{item.quantity}}
                                            </div>
                                            <div class="free_generator__item-task-column-3">
                                                {{(item.price * item.quantity - calculateItemDiscount(item) ) | currency(currency.symbol , 2 , currency.formatted_object) }}
                                                <div>
                                                    <div v-for="(tax, index) in item.taxes" :key="index">
                                                        {{tax.name}}
                                                        {{tax.rate}} %
                                                        ({{( (item.price * item.quantity * tax.rate) / 100 ) | currency(currency.symbol , 2 , currency.formatted_object)}})
                                                    </div>
                                                </div>
                                                <div v-if="item.discount">
                                                    <span v-if="item.discount_type === 'percentage'">
                                                        Discount of {{item.discount}}%:
                                                    </span>
                                                    <span v-else>
                                                        Discount: {{item.discount_type}}
                                                    </span>
                                                    {{calculateItemDiscount(item) | currency(currency.symbol , 2 , currency.formatted_object)}}
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                            <div class="free_generator__row">
                                <div>
                                    <div class="free_generator__payment_terms" v-if="invoice.show_payment_terms">
                                        <label>Payment Terms</label>
                                        <div v-html="invoice.payment_terms ? invoice.payment_terms.replace(/(\\r)*\n/g, '<br />') : ''">
                                        </div>
                                    </div>
                                    <div class="free_generator__client_notes" v-if="invoice.show_client_notes">
                                        <label>Client Notes</label>
                                        <div v-html="invoice.client_notes ? invoice.client_notes.replace(/(\\r)*\n/g, '<br />') : ''">
                                        </div>
                                    </div>
                                </div>
                                <div class="free_generator__payment">
                                    <div class="free_generator__space-between">
                                        <div>
                                            Subtotal
                                        </div>
                                        <div>
                                            {{subTotal | currency(currency.symbol , 2 , currency.formatted_object) }}
                                        </div>
                                    </div>
                                    <div class="free_generator__space-between">
                                        <div>
                                            Discount
                                        </div>
                                        <div>
                                            {{invoiceDiscount | currency(currency.symbol , 2 , currency.formatted_object) }}
                                        </div>
                                    </div>
                                    <div class="free_generator__space-between" :key="index" v-for="(groupTax , index) in groupTaxes()">
                                        <div>
                                            {{index}}
                                        </div>
                                        <div>
                                            {{groupTax.total | currency(currency.symbol , 2 , currency.formatted_object) }}
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="free_generator__space-between">
                                        <div>
                                            Invoice total
                                        </div>
                                        <div>
                                            {{ (subTotalWithTaxes - invoiceDiscount ) | currency(currency.symbol , 2 , currency.formatted_object) }}
                                        </div>
                                    </div>
                                    <!--                                    <div class="free_generator__space-between">-->
                                    <!--                                        <div>-->
                                    <!--                                            Paid to date-->
                                    <!--                                        </div>-->
                                    <!--                                        <div>-->
                                    <!--                                            $0.00-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <div class="free_generator__space-between">
                                        <div>
                                            Amount due ({{currency.code}})
                                        </div>
                                        <div>
                                            {{ (subTotalWithTaxes - invoiceDiscount ) | currency(currency.symbol , 2 , currency.formatted_object) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="invoice.show_payment_stub">
                            <hr class="free_generator__hr-dashed">
                            <div class="free_generator__payment-stub free_generator__row" v-if="invoice.show_payment_stub">
                                <div>
                                    <div>
                                        {{invoice.bill_from.name}}
                                    </div>
                                    <div v-html="invoice.bill_from.address ? invoice.bill_from.address.replace(/(\\r)*\n/g, '<br />') : ''">
                                    </div>
                                    <div>
                                        {{invoice.bill_from.country}}
                                    </div>
                                    <div>
                                        <span>
                                            Tel:
                                        </span>
                                        {{invoice.bill_from.phone}}
                                    </div>
                                    <div>
                                        <span>
                                            Email:
                                        </span>
                                        {{invoice.bill_from.email}}
                                    </div>
                                </div>
                                <div>
                                    <div class="free_generator__space-between">
                                        <div>
                                            Client
                                        </div>
                                        <div>
                                            {{invoice.bill_to.name}}
                                        </div>
                                    </div>
                                    <div class="free_generator__space-between">
                                        <div>
                                            Invoice #
                                        </div>
                                        <div>
                                            {{invoice.number}}
                                        </div>
                                    </div>
                                    <div class="free_generator__space-between" :key="index" v-for="(groupTax , index) in groupTaxes()">
                                        <div>
                                            {{index}}
                                        </div>
                                        <div>
                                            {{groupTax.total | currency(currency.symbol , 2 , currency.formatted_object) }}
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="free_generator__space-between">
                                        <div>
                                            Amount Due ({{currency.code}})
                                        </div>
                                        <div>
                                            {{ (subTotalWithTaxes - invoiceDiscount ) | currency(currency.symbol , 2 , currency.formatted_object) }}
                                        </div>
                                    </div>
                                    <!--                                <div class="free_generator__space-between">-->
                                    <!--                                    <div>-->
                                    <!--                                        Amount Enclosed-->
                                    <!--                                    </div>-->
                                    <!--                                    <div>-->
                                    <!--                                        400.00-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="free_generator__card free_generator__side_action_buttons">
                    <button @click="handleSubmit(preview)"
                            v-if="!showPreviewInvoice"
                            class="primary">
                        Preview Invoice
                    </button>
                    <button v-if="showPreviewInvoice"
                            class="light-blue"
                            @click="showPreviewInvoice = false">
                        <span>
                            Back to edit
                        </span>
                        <span>
                            Back
                        </span>
                    </button>
                    <button v-if="showPreviewInvoice"
                            class="primary"
                            @click="showSend">
                        <span>
                            Email Invoice
                        </span>
                        <span>
                            Email
                        </span>
                    </button>
                    <button v-if="showPreviewInvoice"
                            class="light"
                            @click="invoice.sendInvoiceEmail = invoice.bill_from.email, showDownloadModal = true">
                        <span>
                            Download Invoice
                        </span>
                        <span>
                            Download
                        </span>
                    </button>
                </div>
            </ValidationObserver>
        </div>

        <div class="free_generator__modal" v-if="showSendModal">
            <div class="free_generator__card" v-click-outside="closeSendModal">
                <div v-if="showSendInvoice">
                    <ValidationObserver v-slot="{ invalid }">
                        <div class="free_generator__modal-header">
                            <span>
                                Email Invoice
                            </span>
                            <span @click="showSendModal = false">
                                <img src="./assets/free-generator/times-gray.svg" alt="" class="close">
                            </span>
                        </div>
                        <div class="free_generator__modal-body">
                            <div>
                                <div>
                                    <label>From:</label>
                                    <div>
                                        <ValidationProvider rules="required|email" v-slot="{ errors }">
                                            <input type="text"
                                                   v-model="invoice.bill_from.email"
                                                   placeholder="example@invoicequick.com"
                                                   :class="{'is-invalid' : errors.length > 0}"
                                                   class="invoice_from">
                                        </ValidationProvider>
                                        <img src="./assets/free-generator/message.svg" alt="">
                                    </div>
                                </div>
                                <div>
                                    <label>To:</label>
                                    <div v-for="(recipient, index) in invoice.sendInvoiceData.sendInvoiceEmails" :key="index">
                                        <ValidationProvider :rules="invoice.sendInvoiceData.sendInvoiceEmails.indexOf(recipient) === 0 ? 'required|email' : 'email'" v-slot="{ errors }">
                                            <input type="text"
                                                   :id="index"
                                                   v-model="invoice.sendInvoiceData.sendInvoiceEmails[index]"
                                                   placeholder="example@invoicequick.com"
                                                   :class="{'is-invalid' : errors.length > 0}">
                                        </ValidationProvider>
                                        <img src="./assets/free-generator/message.svg" alt="">
                                        <button @click="invoice.sendInvoiceData.sendInvoiceEmails.splice(index,1)"
                                                v-if="invoice.sendInvoiceData.sendInvoiceEmails.length > 1">
                                            <img src="./assets/free-generator/times-circle.svg" alt="">
                                            Remove
                                        </button>
                                    </div>

                                    <div>
                                        <button class="add-recipient"
                                                @click="invoice.sendInvoiceData.sendInvoiceEmails.push(null)"
                                                :disabled="invoice.sendInvoiceData.sendInvoiceEmails.length >= 3">
                                            <img src="./assets/free-generator/plus.svg" alt="">
                                            Add Recipient
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <label for="subject">Subject</label>
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <input type="text" id="subject" v-model="invoice.sendInvoiceData.subject" placeholder="Invoice . . ." :class="{'is-invalid' : errors.length > 0}">
                                    </ValidationProvider>
                                    <label for="message">Message</label>
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                <textarea name="message"
                                          id="message"
                                          cols="30"
                                          rows="10"
                                          v-model="invoice.sendInvoiceData.message"
                                          placeholder="We have generated a new invoice . . ."
                                          :class="{'is-invalid' : errors.length > 0}"></textarea>
                                    </ValidationProvider>
                                </div>
                            </div>

                        </div>
                        <div class="free_generator__modal-footer">
                            <button @click="sendInvoice"
                                    :disabled="invalid || loading">
                                Send Invoice <img alt="" class="ml-2" src="./assets/free-generator/spinner.svg" v-if="loading">

                            </button>
                        </div>
                    </ValidationObserver>
                </div>
                <div v-else>
                    <div class="free_generator__modal-header">
                        <img src="./assets/free-generator/send.png" alt="">
                        <span @click="showSendModal = false">
                            <img src="./assets/free-generator/times-gray.svg" alt="" class="close">
                        </span>
                        <div>
                            Invoice Sent!
                        </div>
                        <div>
                            We sent your invoice to {{invoice.bill_to.name}}
                        </div>
                    </div>
                    <div class="free_generator__modal-body sent">
                        <img src="./assets/free-generator/modal-background.svg" alt="" class="modal-background">
                        <div>
                            <div>
                                Create a <span>FREE ACCOUNT</span> and get access to:
                            </div>
                            <div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Professional Invoice Templates
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Unlimited Recurring Invoices
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Save Items, Tasks & Taxs
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Invoice Viewed Notifications
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Track Invoice Payments
                                    </span>
                                </div>
                                <div class="free_generator__save-transactions">
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Save Transactions
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Save Customers In One Place
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Unlimited Invoicing for 3 Clients
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        No Credit Card Required!
                                    </span>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                    <div class="free_generator__modal-footer">
                        <button @click="signUp" :disabled="loading">
                            Create Free Account <img alt="" class="ml-2" src="./assets/free-generator/spinner.svg" v-if="loading">
                        </button>
                        <button @click="showSendModal = false, showSendInvoice = true">
                            I don’t like free stuff
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div class="free_generator__modal" v-if="showDownloadModal">
            <div class="free_generator__card" v-click-outside="closeDownloadModal">
                <div v-if="showDownloadSendInvoice">
                    <ValidationObserver v-slot="{ invalid }">
                        <div class="free_generator__modal-header download">
                            <span>
                                Download Invoice
                            </span>
                            <span @click="showDownloadModal = false">
                                <img src="./assets/free-generator/times-gray.svg" alt="" class="close">
                            </span>
                        </div>
                        <div class="free_generator__modal-body">
                            <div>
                                <p>
                                    Send a copy of this invoice to your email?
                                </p>
                            </div>
                            <div class="email-input">
                                <label for="email">Your email</label>
                                <ValidationProvider rules="required|email" v-slot="{ errors }">
                                    <input type="text"
                                           id="email"
                                           v-model="invoice.sendInvoiceEmail"
                                           placeholder="example@invoicequick.com"
                                           :class="{'is-invalid' : errors.length > 0}"
                                           :disabled="invoice.sendEmail === 'no'">
                                </ValidationProvider>
                                <img src="./assets/free-generator/message.svg" alt="">
                            </div>
                            <div class="free_generator__row options">
                                <span>
                                    <input type="radio" id="check1" v-model="invoice.sendEmail" value="yes">
                                    <label for="check1">Yes, please!</label>
                                </span>
                                <span>
                                    <input type="radio" id="check2" v-model="invoice.sendEmail" value="no">
                                    <label for="check2">No, thanks! </label>
                                </span>
                            </div>
                        </div>
                        <hr>
                        <div class="free_generator__modal-footer">
                            <button @click="downloadInvoice()" :disabled="invalid || loading">
                                <span v-if="invoice.sendEmail === 'yes'">
                                    Download & Email Invoice To Me
                                </span>
                                <span v-else>
                                    Download
                                </span>
                                <img alt="" class="ml-2" src="./assets/free-generator/spinner.svg" v-if="loading">
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
                <div v-else>
                    <div class="free_generator__modal-header">
                        <img src="./assets/free-generator/templates.png" alt="">
                        <div>
                            Invoice Downloaded!
                        </div>
                        <span @click="showDownloadModal = false">
                            <img src="./assets/free-generator/times-gray.svg" alt="" class="close">
                        </span>
                    </div>
                    <div class="free_generator__modal-body sent">
                        <img src="./assets/free-generator/modal-background.svg" alt="" class="modal-background">
                        <div>
                            <div>
                                Create a <span>FREE ACCOUNT</span> and get access to:
                            </div>
                            <div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Professional Invoice Templates
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Unlimited Recurring Invoices
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Save Items, Tasks & Taxs
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Invoice Viewed Notifications
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Track Invoice Payments
                                    </span>
                                </div>
                                <div class="free_generator__save-transactions">
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Save Transactions
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Save Customers In One Place
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        Unlimited Invoicing for 3 Clients
                                    </span>
                                </div>
                                <div>
                                    <img src="./assets/free-generator/check-green.svg" alt="">
                                    <span>
                                        No Credit Card Required!
                                    </span>
                                </div>
                                <hr>
                            </div>
                        </div>

                    </div>
                    <div class="free_generator__modal-footer">
                        <button @click="signUp" :disabled="loading">
                            Create Free Account <img alt="" class="ml-2" src="./assets/free-generator/spinner.svg" v-if="loading">
                        </button>
                        <button @click="showDownloadModal = false">
                            I don’t like free stuff
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="free_generator__modal" v-if="showPopUpModal">
            <div class="free_generator__card" v-click-outside="closePopUpModal">
                <div class="free_generator__modal-header download">
                    <span></span>
                     <span @click="showPopUpModal = false">
                        <img src="./assets/free-generator/times-gray.svg" alt="" class="close">
                    </span>
                </div>
                Please disable your pop-up blocker and click the {{this.buttonName}} button again.
            </div>
        </div>
    </div>
</template>

<script>
    import ItemTask from './components/item-task'
    import 'vue2-datepicker/index.css';
    import DatePicker from 'vue2-datepicker';
    import FilePreview from './components/file-preview'
    import moment from "moment";
    import vClickOutside from 'v-click-outside'
    import currencies from './currencies.json'
    import draggable from 'vuedraggable'
    import axios from 'axios';

    export default {
        name: 'App',
        directives: {
            clickOutside: vClickOutside.directive
        },

        data() {
            return {
                loading: false,
                signUpMessage: '',
                baseUrl: 'https://invoicequick.com/',
                currency: {},
                showPreviewInvoice: false,
                showDiscountWholeInvoice: false,
                showSendModal: false,
                showDownloadModal: false,
                showSendInvoice: true,
                showDownloadSendInvoice: true,
                showDateFormatMenu: false,
                currencies: currencies,
                showPopUpModal: false,
                buttonName: '',
                dateFormats: [
                    {
                        id: 1,
                        format: 'MM/DD/YYYY'
                    },
                    {
                        id: 2,
                        format: 'DD/MM/YYYY'
                    },
                    {
                        id: 3,
                        format: 'YYYY/MM/DD'
                    }
                ],
                invoice: {
                    number: 1,
                    currency_id: 108,
                    job_description: null,
                    issued_at: null,
                    due_at: null,
                    logo: null,
                    date_format_id: 1,
                    date_format: 'MM/DD/YYYY',
                    bill_to: {
                        name: null,
                        address: null,
                        country: null,
                        email: null,
                        phone: null,
                    },
                    bill_from: {
                        name: null,
                        address: null,
                        country: null,
                        email: null,
                        phone: null,
                    },
                    free_invoice_items: [
                        {
                            name: null,
                            description: null,
                            price: 0,
                            quantity: 1,
                            taxes: [],
                            discount_type: 'percentage',
                            discount: 0
                        }
                    ],
                    discount_type: 'percentage',
                    discount: 0,
                    payment_terms: null,
                    show_payment_terms: false,
                    client_notes: null,
                    show_client_notes: false,
                    show_payment_stub: false,
                    sendInvoiceData: {
                        sendInvoiceEmails: [null],
                        subject: null,
                        message: null
                    },
                    sendInvoiceEmail: null,
                    sendEmail: 'yes'
                },
            }
        },
        components: {
            ItemTask,
            DatePicker,
            FilePreview,
            draggable
        },
        mounted() {
            this.invoice.issued_at = moment().utc(moment()).format();
            this.invoice.due_at = moment().utc(this.invoice.issued_at).add(15, 'day').format();

            this.currency = currencies.filter(currency => currency.id === this.invoice.currency_id)[0];
        },
        computed: {
            subTotal() {
                let subTotal = 0;
                this.invoice.free_invoice_items.forEach(item => {
                    subTotal += item.price * item.quantity - this.calculateItemDiscount(item);
                });

                return subTotal;
            },
            subTotalWithTaxes() {
                let subTotal = 0;
                this.invoice.free_invoice_items.forEach(item => {
                    subTotal += item.price * item.quantity - this.calculateItemDiscount(item);

                    subTotal += this.sumTaxes(item);
                });

                return subTotal;
            },
            /*
            * Calculate whole invoice discount
            * */
            invoiceDiscount() {
                if (this.invoice.discount_type === 'percentage') {
                    return (this.subTotal * this.invoice.discount) / 100
                } else {
                    return this.invoice.discount;
                }
            },
        },
        watch: {
            'invoice.currency_id': function (newValue) {
                this.currency = currencies.filter(currency => currency.id === newValue)[0];
                this.$forceUpdate();
            },
            'invoice.issued_at': function (newValue) {
                if (this.invoice.due_at < newValue) {
                    this.invoice.due_at = newValue;
                }
            },
            'invoice.due_at': function (newValue) {
                if (this.invoice.issued_at > newValue) {
                    this.$nextTick(() => {
                        this.invoice.due_at = this.invoice.issued_at
                    });
                }
            }
        },
        methods: {
            preview() {
                this.showPreviewInvoice = true;
            },

            addNewItem() {
                this.invoice.free_invoice_items.push(
                    {
                        name: null,
                        description: null,
                        price: 0,
                        quantity: 1,
                        taxes: [],
                        discount_type: 'percentage',
                        discount: 0
                    }
                )
            },
            removeInvoiceItem(index) {
                if (this.invoice.free_invoice_items.length > 1) {
                    this.invoice.free_invoice_items.splice(index, 1)
                }
            },
            calculateItemDiscount(item) {
                let discount = 0;
                if (item.discount !== undefined) {
                    if (item.discount_type === 'percentage') {
                        discount = (item.price * item.quantity * item.discount) / 100
                    } else {
                        discount = item.discount;
                    }
                }
                return discount
            },
            sumTaxes(item) {
                let taxes = 0;
                if (item.taxes) {
                    if (item.taxes.length > 0) {
                        item.taxes.forEach(tax => {
                            taxes = taxes + (((item.price * item.quantity - this.calculateItemDiscount(item)) * tax.rate) / 100);
                        });
                    }
                }
                return taxes;
            },
            addDays(days) {
                this.invoice.due_at = moment(this.invoice.issued_at).add(days, 'day').format();
            },
            showSend() {
                this.showSendModal = true;

                this.invoice.sendInvoiceData.sendInvoiceEmails[0] = this.invoice.bill_to.email;
                this.invoice.sendInvoiceData.subject = `${this.invoice.bill_to.name} Invoice #${this.invoice.number} from ${this.invoice.bill_from.name}`;
                // this.$moment(this.invoice.due_at).format(this.invoice.date_format.format)
                this.invoice.sendInvoiceData.message = "Dear " + this.invoice.bill_to.name + "," + " \n\nThank you for your business, always a pleasure to work with you! \n\nWe have generated a new invoice in the amount of " + this.$options.filters.currency((this.subTotalWithTaxes - this.invoiceDiscount), this.currency.symbol, 2, this.currency.formatted_object) + "\n\nWe would appreciate payment of this invoice by " + moment(this.invoice.due_at).format(this.invoice.date_format);

            },

            closeSendModal() {
                this.showSendModal = false;
                this.showSendInvoice = true;
            },

            closeDownloadModal() {
                this.showDownloadModal = false;
                this.showDownloadSendInvoice = true;
            },

            closeDateFormatMenu() {
                this.showDateFormatMenu = false;
            },

            closePopUpModal() {
                this.showPopUpModal = false
            },
            /*
             * Update specific item inside free_invoice_items array
             * */
            itemChanged(event, index) {
                this.$nextTick(() => {
                    this.invoice.free_invoice_items[index] = event;
                    // Vue.set(this.invoice.free_invoice_items, index, event);
                });
            },
            groupTaxes() {
                let tempTaxArray = [];
                this.invoice.free_invoice_items.forEach(item => {
                    let itemSubTotal = item.price * item.quantity - this.calculateItemDiscount(item);
                    let taxes = 0;
                    if (item.taxes) {
                        if (item.taxes.length > 0) {
                            item.taxes.forEach(tax => {
                                let taxTotal = ((itemSubTotal * tax.rate) / 100);
                                taxes = taxes + taxTotal;
                                tempTaxArray.push({total: taxTotal, name: tax.name, rate: tax.rate});
                            });
                        }
                    }
                });
                let tempGroupedTaxes = this.groupBy(tempTaxArray, tax => tax.name);
                let finalArray = {};
                tempGroupedTaxes.forEach((taxes, key) => {
                    let taxTotal = 0;
                    let rate = 0;

                    if (taxes.length > 0) {
                        taxes.forEach(tax => {
                            taxTotal = taxTotal + tax.total;
                            rate = tax.rate;
                        });
                    }
                    finalArray[key] = {total: taxTotal, rate: rate};
                });
                return finalArray;
            },
            groupBy(list, keyGetter) {
                const map = new Map();
                list.forEach((item) => {
                    const key = keyGetter(item);
                    const collection = map.get(key);
                    if (!collection) {
                        map.set(key, [item]);
                    } else {
                        collection.push(item);
                    }
                });
                return map;
            },
            sendInvoice() {
                this.loading = true;
                axios.post(this.baseUrl + 'free-invoices/send', this.invoice)
                    .then(response => {
                        this.showSendInvoice = false;
                        this.loading = false;
                        console.log(response.data.message)
                    })
                    .catch(function () {
                        this.loading = false;
                    });
            },
            downloadInvoice() {
                this.loading = true;
                axios.post(this.baseUrl + 'free-invoices/download-and-send', this.invoice)
                    .then(response => {

                        this.loading = false;

                        var popUp = window.open(this.baseUrl + 'free-invoices/' + response.data.data.hash + '/download', '_blank');
                        
                        if (popUp == null || typeof (popUp) == 'undefined') {
                            this.buttonName = 'Download'
                            if (this.invoice.sendEmail === 'yes') {
                                this.buttonName = this.buttonName + ' & Email Invoice To Me'
                            }
                            this.showPopUpModal = true;
                        } else {
                            this.showDownloadSendInvoice = false;
                            popUp.focus();
                        }
                    })
                    .catch(function () {
                        this.loading = false;
                    });
            },
            onDragEnd() {

            },
            signUp() {
                this.loading = true;
                axios.post(this.baseUrl + 'register/from-free-generator', this.invoice)
                    .then(response => {
                        this.loading = false;
                        location.href = response.data.data;
                        // window.open(
                        //     response.data.data,
                        //     '_blank'
                        // );
                    }).catch(function () {
                    this.loading = false;
                });
            },
        },

        filters: {
            moment: function (date, format) {
                return moment(date).format(format);
            }
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: 'Inter', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        font-size: 13px;

        textarea, input, button, div, p, span {
            font-family: 'Inter', sans-serif;
            font-weight: 500;

            &::placeholder {
                color: #4A5568;
                opacity: 0.4;
            }
        }

        button {
            cursor: pointer;

            &:disabled {
                opacity: .8
            }

            &:focus {
                outline: none;
                border: 1px solid #4c51bf;
                box-shadow: 0 0 0 3px rgba(180, 198, 252, .45);
            }
        }

        input.is-invalid, textarea.is-invalid {
            border-color: #f8b4b4;
            border-bottom: 1px solid #f8b4b4 !important;
            z-index: 99;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 3px rgba(248, 180, 180, .3);
                border-color: #f8b4b4;
                z-index: 999;
            }
        }

        .invalid-feedback {
            font-size: 10px;
            line-height: 16px;
            color: #dc3545;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .options span {
        display: -webkit-inline-box !important;
    }

    .free_generator__logo .trash img {
        width: 10px !important;
        height: 10px !important;
    }

    .free_generator__currency label {
        display: inline !important;
    }


    .free_generator__card.preview {
        .free_generator__date {
            background: #FFFFFF;
            border: 1px solid #DEE4EC;
            box-sizing: border-box;
            box-shadow: 0 1px 1px rgba(82, 91, 100, 0.1);
            border-radius: 4px;
            padding: 8px;
        }

        .free_generator__logo {
            text-align: right;

            img {
                max-width: 390px;
                max-height: 140px;
                margin: auto;
            }

        }


        .free_generator__payment_terms, .free_generator__client_notes {
            label {
                font-weight: 700;
            }
        }
    }

    .free_generator {
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: #6A7685;
        display: flex;
        position: relative;

        > span {
            display: flex;
            width: 100%;
        }

        &__card {
            position: relative;

            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid #ecf3fb;
            border-radius: 6px;
            overflow: hidden;
            max-width: 920px;
            width: 100%;

            &-body {
                > div:nth-of-type(3) {
                    margin-top: 12px;
                }
            }
        }

        &__card.main {
            min-width: 920px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.02);;
        }

        &__side_action_buttons {
            position: sticky;
            left: auto;
            top: 0;
            right: 5%;
            bottom: auto;
            margin-left: 20px;
            width: 284px;
            padding: 16px;
            background-color: #fff;
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 24px 32px rgba(0, 0, 0, 0.04);
            height: fit-content;
            box-sizing: border-box;
            z-index: 999;

            button {
                width: 100%;
                line-height: 28px;
                font-weight: 700;
                text-align: center;
                padding: 8px 20px;
                border-radius: 4px;
                font-size: 16px;

                span:nth-child(2) {
                    display: none;
                }
            }

            button:not(:nth-of-type(1)) {
                margin-top: 16px;
            }

            button.primary {
                background-color: #3c51be;
                color: #fff;
                border: 1px solid #3c51be;
            }

            button.light {
                border: 2px solid #3c51be;
                background-color: #fff;
                color: #3c51be;
            }

            button.light-blue {
                background: rgba(60, 81, 190, 0.1);
                border: 2px solid rgba(60, 81, 190, 0.1);
                box-sizing: border-box;
                color: #3c51be;
            }
        }

        &__card-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            background-color: white;
            border-bottom: 1px solid #EEF2F7;
            padding: 16px 24px;

            img, span {
                vertical-align: middle;
            }

            div span {
                margin-right: 8px;
            }
        }

        &__space-between {
            display: flex;
            justify-content: space-between;
        }

        &__date {
            position: relative;

            img {
                margin-right: 8px;
            }

            .input {
                background: #FFFFFF;
                border: 1px solid #DEE4EC;
                box-sizing: border-box;
                box-shadow: 0px 1px 1px rgba(82, 91, 100, 0.1);
                border-radius: 4px;
                padding: 4px 8px;
                width: 210px;
            }
        }

        &__item-task.preview {
            > .free_generator__row {
                background: #EEF2F7;
                border-radius: 6px;
                color: #4C51BF;
                padding: 5px 12px;
                margin-bottom: 12px;
            }

            hr {
                margin: 8px 0 8px 0;
            }

            div div .free_generator__row {

                .free_generator__item-task {

                    &-column-6 {
                        color: #4A5568 !important;
                        font-weight: 600;

                        div {
                            font-weight: 500;
                            color: #6A7685;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }

        &__item-task {

            .free_generator__row {
                padding: 0 8px;
            }

            &-column-6 {
                flex: 0 0 50%;
                max-width: 50%;
                padding-right: 4px !important;
                box-sizing: border-box;

                textarea {
                    margin-top: 8px;
                }
            }

            &-column-3 {
                flex: 0 0 25%;
                max-width: 25%;
                padding-right: 4px !important;
                box-sizing: border-box;
                text-align: right;
            }


            &-column-2 {
                flex: 0 0 16.6666666667%;
                max-width: 16.6666666667%;
                padding-right: 4px !important;

                padding-left: 0 !important;
                box-sizing: border-box;
            }

            &-column-1 {
                max-width: 8.33333333%;
                padding-right: 4px !important;
                box-sizing: border-box;
            }
        }

        &__modal {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1040;
            width: 100vw;
            height: 100vh;
            overflow-x: hidden;
            background-color: rgba(0, 0, 0, 0.4);
            box-sizing: border-box;
            font-size: 15px;

            img {
                max-width: unset !important;
            }

            .free_generator {

                &__card {
                    margin: 2% auto;
                    padding: 24px 32px;
                    max-width: 500px;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(82, 91, 100, 0.1);
                    border-radius: 6px;
                    box-sizing: border-box;

                    hr {
                        border: 1px solid #EEF2F7;
                        margin: 20px 0 20px 0;
                        z-index: 999;
                    }
                }

                &__modal-header {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 40px;

                    text-align: center;

                    color: #212C35;
                    margin-bottom: 48px;

                    img.close {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        cursor: pointer;
                    }

                    &.download {
                        margin-bottom: 20px;
                    }

                    div:not(:nth-of-type(1)) {
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;

                        color: #000000;
                    }
                }

                &__modal-footer {
                    text-align: center;
                    display: flex;
                    justify-content: space-around;
                    z-index: 999;

                    button {
                        background-color: #3c51be;
                        color: #fff;
                        border: 1px solid #3c51be;
                        border-radius: 5px;
                        padding: 12px 16px;
                        text-align: center;
                        line-height: 18px;
                        font-size: 14px;
                        margin-top: 6px;
                        z-index: 999;

                        img {
                            margin-left: 8px;
                            vertical-align: middle;
                        }
                    }

                    button:nth-of-type(2) {
                        background: #FFFFFF;
                        border: 1px solid #E3E3E3;
                        box-sizing: border-box;
                        color: #6D6D6D;
                    }
                }

                &__modal-body {
                    label {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 24px;
                        margin-bottom: 4px;
                        color: #4A5568;
                    }

                    p {
                        text-align: center;
                        margin-bottom: 20px;
                    }

                    input, textarea {
                        width: 100%;
                        box-sizing: border-box;
                        background: #FFFFFF;
                        border: 1px solid #DEE4EC;
                        border-radius: 5px;
                        margin-bottom: 8px;
                        line-height: 24px;
                        padding: 4px 8px;
                        max-width: 500px;

                        &:focus {
                            outline: none;
                            box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.3);
                            border-color: #80bdff;
                        }
                    }

                    .invoice_from {
                        padding-right: 8px;
                    }

                    .options {
                        margin-top: 10px;

                        span {
                            display: -webkit-inline-flex;
                        }

                        input {
                            width: 20px;
                            outline: none;
                            margin: auto 4px auto 4px;
                            vertical-align: middle;

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        label {
                            margin-right: 8px;
                        }
                    }

                    .email-input {
                        position: relative;

                        input {
                            padding-left: 40px;
                            margin-top: 4px;
                        }

                        img {
                            position: absolute;
                            top: 41px;
                            left: 11px;
                        }

                        input[type="text"][disabled] {
                            color: lightgray;
                        }
                    }

                    #subject {
                        padding: 4px 8px;
                    }

                    div div {
                        position: relative;

                        input {
                            padding: 4px 94px 4px 32px;
                        }

                        img {
                            position: absolute;
                            top: 10px;
                            left: 8px;
                        }

                        button {
                            position: absolute;
                            top: 5px;
                            right: 8px;
                            background-color: transparent;
                            border: none;
                            outline: none;
                            line-height: 24px;

                            img {
                                margin-right: 8px;
                            }

                            &:focus {
                                border: none !important;
                                box-shadow: none !important;
                            }

                            &.add-recipient {
                                position: static !important;
                                border: 1px solid rgba(76, 81, 191, 0.25);
                                padding: 4px 8px;

                                &:focus {
                                    outline: none;
                                    border: 1px solid #4c51bf !important;
                                    box-shadow: 0 0 0 3px rgba(180, 198, 252, .45) !important;
                                }
                            }
                        }
                    }


                    button {
                        background: #FFFFFF;
                        border: 1px solid rgba(76, 81, 191, 0.25);
                        box-sizing: border-box;
                        border-radius: 5px;
                        line-height: 24px;
                        color: #3C51BE;
                        margin-bottom: 16px;

                        &:hover {
                            color: lighten(#3C51BE, 10%);
                        }

                        img {
                            position: static !important;
                        }

                    }

                }

                &__modal-body.sent {
                    position: relative;

                    > div > div:nth-child(1) {
                        font-size: 19px;
                        line-height: 22px;
                        text-align: center;
                        margin-bottom: 25px;
                        color: #000000;

                        span {
                            text-transform: uppercase;
                            font-weight: 700 !important;
                            color: #403BB2;
                        }
                    }

                    .modal-background {
                        position: absolute;
                        bottom: -98px;
                        left: -31px;
                    }

                    padding: 0 32px;

                    img {
                        position: static;
                        vertical-align: middle;
                        margin-right: 10px;
                    }

                    span {
                        vertical-align: middle;
                    }

                    div div div {
                        display: flex;
                        margin-bottom: 8px;

                        div {
                            display: flex;
                            flex-direction: row;
                            margin-bottom: 8px;
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }

        h3 {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 16px;

            color: #4A5568;
        }

        .text-right {
            text-align: right;
        }

        a {
            color: #4C51BF;
            line-height: 24px;
            cursor: pointer;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.3);
                border-color: #80bdff;
            }

            img {
                margin-right: 8px;
            }
        }

        .input-group {
            span {
                width: 100%;
                display: flex;
            }

            div div:nth-of-type(2), div div:nth-of-type(2) input:first-of-type {
                border-top-left-radius: 6px !important;
                border-top-right-radius: 6px !important;
            }

            div span div:nth-of-type(6), div div:nth-of-type(6) input {
                border-bottom-left-radius: 6px !important;
                border-bottom-right-radius: 6px !important;
                border-bottom: 1px solid #DEE4EC;
            }

            div div:nth-of-type(7) {
                margin-top: 10px;
            }

            input, textarea {
                box-shadow: none;
                border-radius: 0;
                margin: -1px 0 0 0;
                flex: 1;
                -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                -moz-box-sizing: border-box; /* Firefox, other Gecko */
                box-sizing: border-box;
                padding: 8px;
                max-width: 390px;

                &:focus {
                    z-index: 999;
                }
            }
        }

        input.is-invalid, textarea.is-invalid {
            border-color: #f8b4b4;
            border-bottom: 1px solid #f8b4b4 !important;
            z-index: 99;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 3px rgba(248, 180, 180, .3);
                border-color: #f8b4b4;
                z-index: 999;
            }
        }

        input, textarea {
            background: #FFFFFF;
            border: 1px solid #DEE4EC;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 4px 8px;
            line-height: 24px;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.3);
                border-color: #80bdff;
                z-index: 999;
            }
        }

        textarea {
            width: 100%;
        }

        &__input {
            max-width: 64px;
        }

        &__card-body {
            padding: 64px 42px;

            button {
                background: #FFFFFF;
                border: 1px solid #DEE4EC;
                box-sizing: border-box;
                box-shadow: 0px 1px 1px rgba(82, 91, 100, 0.1);
                border-radius: 4px;
                padding: 4px 8px;
                line-height: 24px;
                color: #4C51BF;
                cursor: pointer;

                margin-top: 20px;

                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.3);
                    border-color: #80bdff;
                }
            }
        }

        hr {
            border: none;
            border-bottom: 1px solid #EEF2F7;
            margin: 32px 0;
        }

        &__hr-dashed {
            border-bottom: 1px dashed #EEF2F7 !important;
        }

        &__row {
            display: flex;

            & > div:nth-child(1) {
                padding-right: 28px;
            }

            & > div:nth-child(2) {
                padding-left: 28px;
            }

            div {
                width: 100%;
            }

            input {
                margin-bottom: 8px;
                width: 100%;
            }

            div {
                .free_generator__logo {
                    float: right;
                }
            }
        }

        &__currency {

            img {
                margin-right: 8px;
            }

            label {
                margin-right: 12px;
            }

            select {
                border: 1px solid rgba(178, 187, 198, 0.4);
                box-sizing: border-box;
                box-shadow: 0 1px 1px rgba(82, 91, 100, 0.1);
                border-radius: 4px;
                padding: 4px 28px 4px 4px;
                color: #4A5568;
                background-color: #F9FCFF;
                -moz-appearance: none;
                -webkit-appearance: none;
                background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-repeat: no-repeat;
                background-position-x: 100%;
                max-width: 234px;

                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.3);
                    border-color: #80bdff;
                }
            }
        }

        &__discount-whole-invoice {
            position: relative;

            > img {
                position: absolute;
                right: -28px;
                top: 8px;
                cursor: pointer;
            }

            input {
                padding-right: 60px;
                margin: 0 !important;
            }

            span span {
                display: flex;
                position: absolute;
                top: 3px;
                right: 4px;
                background: #F0F3F7;
                border-radius: 4px;
                padding: 2px;

                div {
                    padding: 0 8px;
                    cursor: pointer;
                }

                div.active {
                    background: #FFFFFF;
                    box-shadow: 0 1px 1px rgba(74, 85, 104, 0.2);
                    border-radius: 3px;
                    color: #3C51BE;
                }
            }

            .free_generator__row {
                margin-bottom: 4px;

                div {
                    margin: auto;
                }
            }

        }

        &__payment {
            .free_generator__space-between {
                margin-bottom: 8px;

                div:nth-child(2) {
                    text-align: right;
                }
            }

            hr {
                margin: 12px 0 8px 0 !important;
            }
        }

        &__payment_terms, &__client_notes {
            margin-bottom: 10px;

            a {
                float: right;
            }

            label {
                display: unset !important;
            }
        }

        &__edit-date-format {
            a:focus {
                outline: none;
                box-shadow: none;
                border-color: transparent;
            }

            img {
                margin-right: 8px;
            }

            &-menu {
                position: absolute;
                right: 30px;
                z-index: 999;
                padding: 12px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(82, 91, 100, 0.1);
                border-radius: 6px;

                div {
                    padding: 6px;
                    cursor: pointer;
                    margin-top: 2px;

                    &.active {
                        background-color: #f7fafc;
                        color: #3C51BE;
                    }

                    &:hover {
                        background-color: #f7fafc;
                    }
                }
            }
        }

        &__item-task {
            margin-top: 24px;
        }

        &__payment-stub {

            > div:first-of-type {
                background: #F6F8FB;
                border-radius: 4px;
                padding: 28px 32px;
                box-sizing: border-box;

            }

            > div {
                margin-top: 24px;
            }

            .free_generator__space-between {

                div:nth-of-type(2) {
                    text-align: right;
                }
            }

            hr {
                margin: 12px 0 8px 0 !important;
            }

        }

    }


    .mx-datepicker {
        width: unset !important;

        &-main {
            z-index: 99;
        }

        &-sidebar {
            width: 131px;
            height: 270px;
            background: #F5F8FB;
            box-sizing: border-box;

            text-align: left;

            .options {
                padding: 4px 8px;

                div {
                    line-height: 24px;
                    padding: 2px 8px;
                    margin-top: 8px;
                    cursor: pointer;
                }
            }

            .options div:hover {
                background: #EEF2F7;
                border-radius: 5px;
                color: #3C51BE;
            }
        }
    }

    .mx-datepicker-content:nth-of-type(2) {
        margin-left: 128px !important;
    }

    .mx-icon-calendar {
        display: none;
    }

    @media only screen and (max-width: 1200px) {
        .free_generator > span {
            display: flex;
            flex-direction: column;

            .free_generator__side_action_buttons {
                order: -33;
                width: 100vw;
                margin: 0;
                right: 0;
                display: flex;
                justify-content: space-between;

                button {
                    padding: 0;
                    font-size: 14px;
                }

                button:not(:first-child) {
                    margin-top: 0;
                    margin-left: 10px;
                }
            }

            .free_generator__card:first-of-type {
                order: 2;
            }
        }

        .free_generator__card.main {
            min-width: unset !important;
        }
        .free_generator__card.preview > div.free_generator__card-body > div:nth-child(1) {
            /*text-align: center;*/

            div:nth-child(2) {
                margin-top: 16px;
            }
        }
    }

    @media only screen and (max-width: 805px) {

        .free_generator__save-transactions {
            display: none !important;
        }

        .free_generator__modal .free_generator__modal-body.sent .modal-background {
            bottom: -130px !important;
        }


        .free_generator__card.preview > div.free_generator__card-body > div:nth-child(1) {
            text-align: center;

        }

        .free_generator__card-body {
            padding: 20px;
        }

        .free-generator__item-task-wrapper .trash-button, .free_generator__discount-whole-invoice > img {
            right: -16px;
        }

        .dots {
            left: -24px !important;
        }

        .free_generator__item-task {

            &-column-1 {
                max-width: 12% !important;
            }

            &-column-3 {
                max-width: 21% !important;
            }
        }

        .free-generator__item-task {

            &-column-1 {
                max-width: 12% !important;
            }

            &-column-3 {
                max-width: 21% !important;
            }
        }

        .free_generator__card.preview .free_generator__logo {
            text-align: center;
        }

        div.free_generator__item-task.preview div.free_generator__row {
            display: flex !important;
        }

        .free_generator > span {
            .free_generator__row {
                display: block;
                padding: 0 !important;

                input, textarea {
                    max-width: unset;
                }

                & > div:nth-child(2) {
                    padding-left: 0;
                }

                &.input-group > div:nth-child(2) {
                    margin-top: 16px;
                }
            }
        }

        div.free_generator__card-body > div:nth-child(5) > span > div {

            & > div.free_generator__row {
                display: flex !important;
            }
        }

        .text-right {
            text-align: left !important;
            margin-top: 8px;
        }

        .free_generator__discount-whole-invoice {

            span {
                top: 27px;
            }

            img {
                top: 11px;
            }

        }

        .free_generator__row > div:first-child {
            padding-right: 0 !important;
        }
    }

    @media only screen and (max-width: 755px) {

        .free_generator__card-header {

            div:nth-child(2) {
                display: flex;
                flex-direction: column;
                justify-items: center;

                span:not(:first-child) {
                    margin-top: 4px;
                }
            }
        }

        div.free_generator__card-body > div:nth-child(5) > div > div:nth-child(2) > div > div > div.free-generator__row {
            display: block;

            & > div:nth-child(2) {
                margin-top: 8px;
            }

        }

        div.free_generator__card-body > div:nth-child(5) > span > div {

            & > div.free_generator__row {
                display: flex !important;
            }

            & > div:nth-child(2) > div > div > div > div.free-generator__row {

                display: block !important;
            }

            .free-generator__item-task__add-discount {
                margin-top: 12px;
            }
        }

        div.free-generator__item-task__add-tax {
            display: block;

            &-row {
                display: block;
            }

            button {
                margin-top: 8px !important;
            }
        }

        .free_generator__currency {
            display: block !important;

            select {
                margin: 0;
            }
        }

    }

    @media only screen and (max-width: 550px) {

        .free_generator__side_action_buttons button {
            span:nth-child(1) {
                display: none;
            }

            span:nth-child(2) {
                display: block;
            }
        }
    }

    @media only screen and (max-width: 420px) {
        .free_generator__modal-body.sent {
            padding: 0 !important;
        }


        .free_generator__item-task {
            textarea {
                width: 200% !important;
            }
        }
    }

    @media only screen and (max-width: 350px) {

        .free_generator__card-header {
            display: flex;
            flex-direction: column;
            justify-items: center;

            div:nth-child(2) {
                margin-top: 12px;
            }
        }

        .free_generator__edit-date-format-menu {
            right: unset;
            left: 24px;
        }

        .mx-datepicker {
            &-sidebar {
                width: unset;
            }
        }
        .mx-calendar {
            width: unset !important;
        }
    }
</style>
