<template>
    <div class="free-generator__item-task-wrapper">
        <div class="free-generator__item-task-container">
            <div>
                <div class="free-generator__item-task-column-6">
                    <span>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <input type="text" placeholder="Item/Task name" v-model="item.name" :class="{'is-invalid' : errors.length > 0}">
                        </ValidationProvider>
                            <textarea placeholder="Item/Task description" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' v-model="item.description">
                            </textarea>

                    </span>
                </div>
                <div class="free-generator__item-task-column-2">
                    <span>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <input type="number" v-model="item.price" :class="{'is-invalid' : errors.length > 0}">
                        </ValidationProvider>
                    </span>
                </div>
                <div class="free-generator__item-task-column-1">
                    <span>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <input type="number" v-model="item.quantity" :class="{'is-invalid' : errors.length > 0}">
                        </ValidationProvider>
                    </span>
                </div>
                <div class="free-generator__item-task-column-3">
                    <span>
                        {{itemTotal | currency(currency.symbol , 2 , currency.formatted_object)}}
                    </span>
                </div>
            </div>
            <span class="trash-button" @click="$emit('remove')">
                <img src="./../assets/free-generator/trash.svg" alt="img">
            </span>
            <span class="dots handle">
                <img src="./../assets/free-generator/dots.svg" alt="img">
            </span>
            <div class="free-generator__row">
                <div class="free-generator__item-task__add-tax">
                    Tax:
                    <div class="free-generator__item-task__add-tax-row" v-if="item.taxes.length > 0">
                        <div :key="taxIndex+1-1" v-for="(tax , taxIndex) in item.taxes">
                        <span @click="item.taxes.splice(taxIndex , 1)">
                             <img src="./../assets/free-generator/times.svg" alt="img">
                        </span>

                            {{tax.name}} {{tax.rate}}% ({{( (itemTotal * tax.rate) / 100 ) | currency(currency.symbol , 2 , currency.formatted_object)}})
                            <template v-if="item.taxes.length !== (taxIndex+1)">
                                ,
                            </template>
                        </div>
                    </div>
                    <button @click="showAddTaskMenu = !showAddTaskMenu">
                        Add Tax
                        <img src="./../assets/free-generator/caret-down-blue.svg" alt="">
                    </button>
                    <div class="free-generator__item-task__add-tax__dropdown-body" v-if="showAddTaskMenu" v-click-outside="closeAddTaskMenu">
                        <ValidationObserver v-slot="{ handleSubmit }">
                        <div>
                            <span>
                                Tax Name:
                            </span>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <input v-model="newTax.name" type="text" :class="{'is-invalid' : errors.length > 0}">
                            </ValidationProvider>
                        </div>
                        <div>
                            <span>
                                Tax Amount:
                            </span>
                            <ValidationProvider rules="required|positive|max_value:99|min_value:1" v-slot="{ errors }">
                                <input v-model="newTax.rate"
                                       type="text"
                                       :class="{'is-invalid' : errors.length > 0}">
                            </ValidationProvider>
                            <span>
                                %
                            </span>
                        </div>

                        <button @click="handleSubmit(addTax)">
                            <img src="./../assets/free-generator/check.svg" alt="">
                            Add tax
                        </button>
                        </ValidationObserver>
                    </div>
                </div>
                <div class="free-generator__item-task__add-discount">
                    <span>
                        Discount:
                    </span>
                    <button @click="showAddDiscountMenu = !showAddDiscountMenu" v-if="item.discount === 0">
                        Apply discount
                        <img src="./../assets/free-generator/caret-down.svg" alt="">
                    </button>
                    <button @click="showAddDiscountMenu = !showAddDiscountMenu" v-else class="discount-applied">
                        <img src="./../assets/free-generator/times-gray.svg" alt="" @click="item.discount = 0">
                        Discount - {{itemDiscount | currency(currency.symbol , 2 , currency.formatted_object)}}
                    </button>
                    <div class="free-generator__item-task__add-discount__dropdown-body" v-if="showAddDiscountMenu" v-click-outside="()=>{this.showAddDiscountMenu = false}">
                        <div>
                            <div>
                                <div :class="item.discount_type === 'percentage' ? 'active' : ''"
                                     @click="item.discount_type = 'percentage' , itemChanged(item)">
                                    Percentage (%)
                                </div>
                                <div :class="item.discount_type === 'flat' ? 'active' : ''"
                                     @click="item.discount_type = 'flat'  , itemChanged(item)">
                                    Flat ($)
                                </div>
                            </div>
                        </div>
                        <div v-if="item.discount_type === 'percentage'">
                            <div>
                                <span>
                                    Discount
                                </span>
                                <ValidationProvider rules="required|positive|max_value:100|min_value:1" v-slot="{ errors }">
                                <input type="number" v-model="item.discount" :class="{'is-invalid' : errors.length > 0}">
                                </ValidationProvider>
                            </div>
                            <button @click="showAddDiscountMenu = false">
                                <img src="./../assets/free-generator/check.svg" alt="">
                                Apply Discount
                            </button>
                        </div>
                        <div v-if="item.discount_type === 'flat'">
                            <div>
                                <span>
                                    Discount
                                </span>
                                <input type="number" v-model="item.discount">
                            </div>
                            <button @click="showAddDiscountMenu = false">
                                <img src="./../assets/free-generator/check.svg" alt="">
                                Apply Discount
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'

    export default {
        name: 'ItemTask',
        directives: {
            clickOutside: vClickOutside.directive
        },
        data() {
            return {
                showAddDiscountMenu: false,
                showAddTaskMenu: false,
                newTax: {
                    name: null,
                    rate: null
                }
            }
        },
        props: {
            item: {
                type: Object,
                required: true,
                default: () => {
                    return {
                        name: {}
                    };
                },
            },
            currency: {
                type: Object,
                required: true,
                default: () => {
                    return {
                        name: {}
                    };
                },
            },
        },
        computed: {
            /*
            * Item total
            * */
            itemTotal() {
                let total = this.item.price * this.item.quantity - this.itemDiscount;
                if (isNaN(total)) {
                    return 0;
                }
                return total;
            },
            /*
            * Item discount
            * */
            itemDiscount() {
                let discount = 0;
                if (this.item.discount !== undefined) {
                    if (this.item.discount_type === 'percentage') {
                        discount = (this.item.price * this.item.quantity * this.item.discount) / 100
                    } else {
                        discount = this.item.discount;
                    }
                }
                return discount
            }
        },
        methods: {
            closeAddTaskMenu() {
                this.showAddTaskMenu = false
            },
            /*
            * Emit item-changed event to parent
            * */
            itemChanged(item) {
                this.$emit('item-changed', item);
                this.$forceUpdate();
            },
            addTax() {
                if (!this.item.taxes.filter(tax => tax.name.toLowerCase().indexOf(this.newTax.name.toLowerCase()) > -1).length) {
                    this.item.taxes.push({name: this.newTax.name, rate: this.newTax.rate});
                    this.itemChanged(this.item);
                    this.newTax = {name: null, rate: null}
                    this.closeAddTaskMenu();
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .free-generator__item-task {

        &-wrapper {
            position: relative;
            margin-top: 8px;

            .trash-button {
                position: absolute;
                right: -24px;
                top: 12px;
                cursor: pointer;

                &:hover {
                }
            }

            .dots {
                cursor: pointer;
                position: absolute;
                left: -28px;
                top: 12px;
            }
        }

        &-container {
            background: #F6F8FB;
            border-radius: 4px;
            padding: 8px;

            > div {
                display: flex;
            }

            input {
                width: 100%;
                box-sizing: border-box;
            }
        }

        &-column-6 {
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 4px;
            box-sizing: border-box;

            textarea {
                margin-top: 8px;
                width: unset;
                max-width: 406px;
            }


        }

        &-column-3 {
            flex: 0 0 25%;
            max-width: 25%;
            padding-right: 4px;
            box-sizing: border-box;
            text-align: right;
        }


        &-column-2 {
            flex: 0 0 16.6666666667%;
            max-width: 16.6666666667%;
            padding-right: 4px;
            box-sizing: border-box;
        }

        &-column-1 {
            max-width: 8.33333333%;
            padding-right: 4px;
            box-sizing: border-box;
        }

        &__add-tax {
            position: relative;
            display: flex;

            &-row {
                display: flex;
                margin-left: 8px;
                background-color: white;
                padding: 0 4px;
                border: 1px solid rgba(76, 81, 191, 0.25) !important;
                border-radius: 5px;
                color: #4C51BF;

                span {
                    cursor: pointer;
                    margin-right: 4px;
                    margin-left: 4px;

                    img {
                        width: 8px;
                        margin: 0;
                    }
                }
            }

            > button {
                margin-left: 8px;
            }

            button {
                margin-top: 0 !important;
                margin-right: 8px;
                padding: 0 8px !important;
                background: #F5F6FC !important;
                border: 1px solid rgba(76, 81, 191, 0.25) !important;
                box-sizing: border-box;
                border-radius: 5px;
            }

            img {
                margin: 0 8px;
            }

            &__dropdown-body > span {
                background: #FFFFFF;
                border: 1px solid #EEF0F2;
                box-sizing: border-box;
                box-shadow: 0px 4px 8px rgba(82, 91, 100, 0.1);
                border-radius: 6px;
                position: absolute;
                left: 30px;
                top: 38px;
                padding: 12px;
                min-width: 244px;
                z-index: 99;

                > div {
                    position: relative;
                    display: flex;
                    padding: 4px;
                    justify-content: space-between;

                    input {
                        max-width: 120px;
                        padding-right: 20px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }

                    span:nth-of-type(3) {
                        position: absolute;
                        top: 8px;
                        right: 12px;
                        z-index: 999;
                    }
                }

                button {
                    display: block;
                    padding: 4px !important;
                    width: 100%;
                    background: #4C51BF !important;
                    border-radius: 5px;
                    color: white !important;
                    margin-top: 12px !important;
                    box-sizing: border-box;

                    img {
                        margin: 0 8px 0 0 !important;
                    }
                }
            }
        }

        &__add-discount {
            position: relative;

            .discount-applied {
                border: 1px solid rgba(99, 115, 129, 0.15) !important;
                background-color: white !important;
                color: #0DA72F !important;
                padding: 0 6px !important;

                img {
                    margin: 0 4px 0 0 !important;
                    width: 8px;
                }
            }

            button {
                margin-top: 0 !important;
                margin-right: 8px;
                padding: 0px 0 0px 8px !important;
                background: #EDEEF0 !important;
                border: none !important;
                border-radius: 5px;
                color: #4A5568 !important;
            }

            img {
                margin: 0 8px;
            }

            &__dropdown-body {
                position: absolute;
                top: 38px;
                left: 62px;
                background: #FFFFFF;
                border: 1px solid #EEF0F2;
                box-sizing: border-box;
                box-shadow: 0 4px 8px rgba(82, 91, 100, 0.1);
                border-radius: 6px;
                padding: 8px;
                min-width: 244px;
                z-index: 99;

                div:first-of-type div {
                    display: flex;
                    padding: 2px;
                    background: #F0F3F7;
                    border-radius: 4px;

                    div {
                        cursor: pointer;
                        padding: 0 4px;
                        width: 100%;
                        justify-content: center;
                    }

                    div.active {
                        background: #FFFFFF;
                        box-shadow: 0 1px 1px rgba(74, 85, 104, 0.2);
                        border-radius: 3px;
                        color: #3C51BE;
                    }
                }

                div:nth-of-type(2) > div {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 8px;

                    span {
                        margin: auto 0 auto 0;
                    }

                    span:first-child {
                        margin: auto 12px auto 0;
                    }
                }

                button {
                    display: block;
                    width: 100%;
                    padding: 4px !important;
                    background: #4C51BF !important;
                    border-radius: 5px;
                    color: white !important;
                    margin-top: 12px !important;
                }

            }
        }
    }
</style>
